import * as angular from 'angular';
import {RegisteredDomainResource} from "../../../http/target/classes/openapi/code/api/RegisteredDomainResource";

angular.module('virtual-try-on-manager')
    .controller('RegisteredDomainListCtrl', function ($scope, RegisteredDomainResource: RegisteredDomainResource) {
        RegisteredDomainResource.findAll({}).$promise.then(domains => $scope.registeredDomains = domains);
        $scope.columns = [
            {
                name: 'domain', labelCode: 'domain', link: function (registeredDomain) {
                    return '/admin/registered-domain/' + registeredDomain.id
                }
            },
            {
                name: 'customerGroup.name',
                labelCode: 'customers.group',
                link: function (registeredDomain) {
                    if (!registeredDomain.customerGroup) {
                        return null;
                    }
                    return '/admin/customer-group/' + registeredDomain.customerGroup.id
                }
            }
        ];
    });
