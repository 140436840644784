import {mainModule} from "../app";
import {
    Configuration,
    ManagedFilesApi,
    Middleware,
    ProductsApi,
    ResponseContext,
    SoftwareReleasesApi,
    ImageStylesApi,
    ScenariosApi
} from "vtom-api-typescript-client";
import {IPromise, IQService, IScope} from "angular";
import HttpConfig from "../framework/http/http-config";


// Make each api available for injection
const apis = {
    SoftwareReleasesApi: SoftwareReleasesApi,
    ManagedFilesApi: ManagedFilesApi,
    ProductsApi: ProductsApi,
    ScenariosApi: ScenariosApi,
    ImageStylesApi: ImageStylesApi
};

for (const key in apis) {
    mainModule.factory(key, ['$rootScope', '$q', function ($rootScope: IScope, $q: IQService) {
        /**
         * Wrap API calls in $q so that angular knows when to update its scope.
         */
        const wrapInAngularPromise = obj => {
            Object.getOwnPropertyNames(obj.__proto__).forEach(methodName => {
                if (methodName == 'constructor') {
                    return;
                }
                const original = obj[methodName];
                obj[methodName] = function () {
                    return $q.when(original.apply(obj, arguments));
                }
            });
            return obj;
        };


        const configuration = new Configuration({
            basePath: './api',
            middleware: [new VtomMiddleware($rootScope, $q)]
        });

        return wrapInAngularPromise(new apis[key](configuration));
    }]);
}

class VtomMiddleware implements Middleware {
    constructor(private readonly $rootScope: IScope,
                private readonly $q: IQService) {
    }

    private toAngularRejection(context: ResponseContext): IPromise<any> {
        return this.$q.when(context.response.json()).then(data => {
            return {
                data: data,
                status: context.response.status,
                config: {
                    url: context.url,
                    method: context.init.method,
                    custom: {
                        displayModalOnError: true
                    }
                }
            }
        });
    }


    post(context: ResponseContext): Promise<Response | void> {
        if (!context.response.ok) {
            this.toAngularRejection(context).then(rejection => {
                if (context.response.status == 401) {
                    this.$rootScope.$emit(HttpConfig.HTTP_UNAUTHORIZED_EVENT, rejection);
                } else {
                    this.$rootScope.$emit(HttpConfig.HTTP_ERROR_EVENT, rejection);
                }
            });
        }
        return;
    }

}
